var render, staticRenderFns
import script from "./GoodsRecommend.vue?vue&type=script&lang=js&"
export * from "./GoodsRecommend.vue?vue&type=script&lang=js&"
import style0 from "./GoodsRecommend.vue?vue&type=style&index=0&id=b3b3b528&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3b3b528",
  null
  
)

/* custom blocks */
import block0 from "./GoodsRecommend.vue?vue&type=custom&index=0&blockType=div&class=goods-recommend&v-loading=loading"
if (typeof block0 === 'function') block0(component)

export default component.exports